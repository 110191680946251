import Vue from 'vue';
import VueRouter from 'vue-router';
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }
Vue.use(VueRouter);

const routes = [
  // {
  //       path: '/',
  //       name: 'mall',
  //       component: ()=>import('@/view/mall'),
  //     },
  {
    path: '/',
    name: 'home',
    component: () => import('@/view/home'),
    children: [{
        path: '/index',
        name: 'index',
        component: () => import('@/view/index'),
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/view/login'),
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('@/view/register'),
      },
      {
        path: '/repassword',
        name: 'repassword',
        component: () => import('@/view/repassword'),
      },
      {
        path: '/brand',
        name: 'brand',
        mate: {
          title: '品牌中心'
        },
        component: () => import('@/view/brand'),
      },
      {
        path: '/actions',
        name: 'actions',
        mate: {
          title: '品牌动态'
        },
        component: () => import('@/view/actions'),
      },
      {
        path: '/articleDetail',
        name: 'articleDetail',
        mate: {
          title: '品牌中心详情页'
        },
        component: () => import('@/view/articleDetail'),
      },
      {
        path: '/mall',
        name: 'mall',
        mate: {
          title: '品牌商城'
        },
        component: () => import('@/view/mall'),
      },
      {
        path: '/goodsDetail',
        name: 'goodsDetail',
        mate: {
          title: '商品详情'
        },
        component: () => import('@/view/goodsDetail'),
      },
      {
        path: '/joining',
        name: 'joining',
        mate: {
          title: '加入我们'
        },
        component: () => import('@/view/joining/index'),
      },
      {
        path: '/joiningNotice',
        name: 'joiningNotice',
        mate: {
          title: '网站公告'
        },
        component: () => import('@/view/joining/notice'),
      },
      {
        path: '/joiningService',
        name: 'joiningService',
        mate: {
          title: '服务协议'
        },
        component: () => import('@/view/joining/service'),
      },
      {
        path: '/joiningQuestion',
        name: 'joiningQuestion',
        mate: {
          title: '常见问题'
        },
        component: () => import('@/view/joining/question'),
      },
      {
        path: '/joiningJob',
        name: 'joiningJob',
        mate: {
          title: '诚聘英才'
        },
        component: () => import('@/view/joining/job'),
      },
      {
        path: '/joiningContract',
        name: 'joiningContract',
        mate: {
          title: '联系我们'
        },
        component: () => import('@/view/joining/contract'),
      },
      {
        path: '/account',
        name: 'account',
        mate: {
          title: '基本资料'
        },
        component: () => import('@/view/account/base'),
      },
      {
        path: '/accountTruthName',
        name: 'accountTruthName',
        mate: {
          title: '实名认证'
        },
        component: () => import('@/view/account/truthName'),
      },
      {
        path: '/accountAddress',
        name: 'accountAddress',
        mate: {
          title: '收货地址'
        },
        component: () => import('@/view/account/address'),
      },
      {
        path: '/accountOrder',
        name: 'accountOrder',
        mate: {
          title: '我的订单'
        },
        component: () => import('@/view/account/order'),
      },
      {
        path: '/accountCart',
        name: 'accountCart',
        mate: {
          title: '我的购物车'
        },
        component: () => import('@/view/account/cart'),
      },
      {
        path: '/createOrder',
        name: 'createOrder',
        mate: {
          title: '创建订单'
        },
        component: () => import('@/view/account/createOrder'),
      },
      {
        path: '/orderDetail',
        name: 'orderDetail',
        mate: {
          title: '订单详情'
        },
        component: () => import('@/view/account/orderDetail'),
      },
      {
        path: '/accountTuijian',
        name: 'accountTuijian',
        mate: {
          title: '我的推荐'
        },
        component: () => import('@/view/account/tuijian'),
      },
    ]
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;