import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
Vue.use(Vuex)

const vuexLocal = new VuexPersist({
    Storage: window.localStorage
})
export default new Vuex.Store({
  state: {
      menuIndex: 'index',
      accountIndex: 'account',
      tagList: [],
      homeData:{},
      rollList: [],
      token: '',
      userInfo:{}
  },
  mutations: {
        SET_INFO(state,e){
            if (e) {
                state[e[0]] = e[1];
            }
        },
        //向tagList中添加数据
        pushTag(state,e){
           if(!state.tagList.includes(e)){
               state.tagList.push(e)
           }
        }
  },
  actions: {
  },
  modules: {
  },
  getters:{
      banner(state){
          return  state.homeData.banner
      },
      rollList(state){
          return state.rollList
      }
  },
  plugins: [vuexLocal.plugin]
})
